import useAuthApi from 'hooks/useAuthApi'
import { UseQueryOptions } from '@tanstack/react-query'
import { MasterLedgerHttpClient } from '../network'
import {
    MasterLedgerGetRewardClaimsRequest,
    MasterLedgerGetRewardClaimsResponse,
    MasterLedgerGetRewardsRequest,
    MasterLedgerGetRewardsResponse,
} from '../types'

export const MASTER_LEDGER_QUERY_KEY = 'master_ledger'

export const useRewardsQuery = (
    params: MasterLedgerGetRewardsRequest,
    options?: Omit<UseQueryOptions<MasterLedgerGetRewardsResponse>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [MASTER_LEDGER_QUERY_KEY, 'rewards', params],
        queryFn: (token) => MasterLedgerHttpClient.setAccessToken(token).GetUserRewards(params),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })

export const useRewardClaimsQuery = (
    params: MasterLedgerGetRewardClaimsRequest,
    options?: Omit<UseQueryOptions<MasterLedgerGetRewardClaimsResponse>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [MASTER_LEDGER_QUERY_KEY, 'claims', params],
        queryFn: (token) =>
            MasterLedgerHttpClient.setAccessToken(token).GetUserRewardClaims(params),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })
