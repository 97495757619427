import removeTrailingSlash from 'helpers/removeTrailingSlash'

const API_SITE_BASE = removeTrailingSlash(process.env.NEXT_PUBLIC_SITE_API_URL as string)
const API_GAME_DATA_BASE = removeTrailingSlash(process.env.NEXT_PUBLIC_GAME_DATA_API_URL as string)
const API_PLAYER_BASE = removeTrailingSlash(process.env.NEXT_PUBLIC_PLAYER_API_URL as string)
const API_NODE_BASE = removeTrailingSlash(process.env.NEXT_PUBLIC_NODE_API_URL as string)
const API_MASTER_LEDGER_BASE = removeTrailingSlash(
    process.env.NEXT_PUBLIC_MASTER_LEDGER_API_URL as string,
)
const API_MARKETPLACE_BASE = removeTrailingSlash(
    process.env.NEXT_PUBLIC_MARKETPLACE_API_URL as string,
)
const API_AZURE_MARKETPLACE_BASE = removeTrailingSlash(
    process.env.NEXT_PUBLIC_AZURE_MARKETPLACE_API_URL as string,
)

export const API_SIGN_IN = `${API_PLAYER_BASE}/v1/auth/signin`
export const API_SIGNIN_GRANT = `${API_PLAYER_BASE}/v1/auth/grant`
export const API_REGISTER = `${API_PLAYER_BASE}/v1/auth/register`
export const API_CONFIRM_REGISTER = `${API_PLAYER_BASE}/v1/auth/register/confirm`
export const API_REFRESH_ACCESS_TOKEN = `${API_PLAYER_BASE}/v1/auth/refresh_token`
export const API_CHECK_USERNAME = `${API_PLAYER_BASE}/v1/auth/username/check`

export const API_GET_USER = (id: number) => `${API_PLAYER_BASE}/v1/player_users/${id}`

export const API_MFA_ASSOCIATE = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/mfa/associate`
export const API_MFA_CONNECT = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/mfa/connect`
export const API_MFA_DISCONNECT = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/mfa/disconnect`

export const API_MFA_SIGNIN = (id: number) => `${API_PLAYER_BASE}/v1/auth/${id}/mfa`

export const API_USER_RECOVER_PASSWORD = `${API_PLAYER_BASE}/v1/recovery/password`
export const API_USER_RECOVER_PASSWORD_CONFIRM = `${API_PLAYER_BASE}/v1/recovery/password/confirm`
export const API_USER_RESEND_CONFIRMATION_CODE = `${API_PLAYER_BASE}/v1/auth/register/confirm/resend`

export const API_USER_SYNC_ROLES = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/nft_asset_roles/sync`
export const API_USER_ATTRIBUTE_USERNAME = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/username`
export const API_USER_ATTRIBUTE_AVATAR = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/avatar`
export const API_USER_ATTRIBUTE_PASSWORD = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/password`
export const API_USER_ATTRIBUTE_EMAIL = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/email`
export const API_USER_ATTRIBUTE_EMAIL_CONFIRM = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/email/confirm`
export const API_USER_ATTRIBUTE_EMAIL_CHANGE_CANCEL = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/email/cancel`
export const API_DELETE_USER = (id: number) => `${API_PLAYER_BASE}/v1/player_users/${id}`
export const API_UNDELETE_USER = (id: number) => `${API_PLAYER_BASE}/v1/player_users/${id}/undelete`

export const API_USER_VERIFY_WALLET_CARDANO = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/create/cardanoDataSign`
export const API_USER_VERIFY_WALLET_EVM = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/create/evmDataSign`
export const API_USER_VERIFY_HARDWARE_WALLET_CARDANO = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/create/cardanoVerificationTx`
export const API_USER_GET_HARDWARE_WALLET_CARDANO_CHALLENGE = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/verificationTx`
// NOT IN USE
export const API_USER_UNVERIFY_WALLET = (id: number, walletId: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/${walletId}`

export const API_USER_BULK_VERIFY_WALLET = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/bulk`
export const API_USER_BULK_UNVERIFY_WALLET = (id: number) =>
    `${API_PLAYER_BASE}/v1/player_users/${id}/verified_wallet_addresses/bulk`

export const API_USER_DISCORD_AUTHORIZE = `${API_PLAYER_BASE}/v1/integrations/discord/authorize`
export const API_USER_DISCORD_CONNECT = `${API_PLAYER_BASE}/v1/integrations/discord/connect`
export const API_USER_DISCORD_DISCONNECT = `${API_PLAYER_BASE}/v1/integrations/discord/disconnect`

export const API_USER_KYC_GET_SESSION = `${API_PLAYER_BASE}/v1/synaps/session`

export const API_GET_USER_COPI_BALANCE = `${API_PLAYER_BASE}/v1/blockchain/file-nodes/copi`
export const API_MASTER_LEDGER_GET_REWARDS = `${API_MASTER_LEDGER_BASE}/v1/rewards`
export const API_MASTER_LEDGER_GET_REWARD_CLAIMS = `${API_MASTER_LEDGER_BASE}/v1/claims`
export const API_MASTER_LEDGER_GET_CLAIM_PARAMETERS = `${API_MASTER_LEDGER_BASE}/v1/claims/parameters`
export const API_MASTER_LEDGER_GET_CLAIM_CREATE = `${API_MASTER_LEDGER_BASE}/v1/claims/create`

/**
 * Blog endpoints
 */

export const API_GET_SITE_CATEGORIES = `${API_SITE_BASE}/v1/categories`
export const API_GET_SITE_POSTS = `${API_SITE_BASE}/v1/posts`
export const API_GET_SITE_POST = (slug: string) => `${API_SITE_BASE}/v1/posts/${slug}`

export const API_GET_NFT_METADATA_SEARCH = `${API_MARKETPLACE_BASE}/nft/search`

/**
 * Marketplace - Custom Tag endpoints
 */

export const API_MARKETPLACE_CT_SETTINGS_ACTIVE = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tag_settings/active`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_ACTIVE = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/active`

export const API_MARKETPLACE_CT_PAYMENT_ORDER = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${id}`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_CREATE = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_ADD_TAG = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${id}/custom_tags`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_AVAILABILITY = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${id}/custom_tags/check`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE = (
    playerSub: string,
    paymentOrderId: number,
    id: number,
) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${paymentOrderId}/custom_tags/${id}`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE_ALL = (
    playerSub: string,
    paymentOrderId: number,
) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${paymentOrderId}/custom_tags`

export const API_MARKETPLACE_CT_PAYMENT_ORDER_REFRESH = (
    playerSub: string,
    paymentOrderId: number,
) => `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payment_orders/${paymentOrderId}/refresh`

export const API_MARKETPLACE_CT_PAYMENT_CREATE = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payments`

export const API_MARKETPLACE_CT_PAYMENT_CANCEL = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payments/${id}/cancel`

export const API_MARKETPLACE_CT_PAYMENT_GET = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payments/${id}`

export const API_MARKETPLACE_CT_PAYMENTS = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/payments`

export const API_GET_USER_UNASSIGNED_CUSTOM_TAGS = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tags/unassigned`

export const API_ASSIGN_USER_PROFILE_CUSTOM_TAG = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tags_assignments/profile`

export const API_GET_USER_ASSIGNED_CUSTOM_TAGS = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tags_assignments`

export const API_REMOVE_USER_CUSTOM_TAG_ASSIGNMENT = (playerSub: string, id: number) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tags_assignments/${id}`

export const API_GET_USER_ASSIGNED_PROFILE_CUSTOM_TAG = (playerSub: string) =>
    `${API_MARKETPLACE_BASE}/v1/player_users/${playerSub}/custom_tags_assignments/profile/active`

/**
 * Azure Marketplace
 */

export const API_AZURE_MARKETPLACE_GET_SALE_OVERVIEW_STATS = `${API_AZURE_MARKETPLACE_BASE}/api/SendMessageTypeless/GetSaleOverviewStatsRequest`

/**
 * Game Data
 */

export const API_GAME_DATA_RACE_LEADERBOARD = `${API_GAME_DATA_BASE}/SendMessageTypeless/RaceLeaderboardRequest`
export const API_GAME_DATA_HOLOCACHE_LEADERBOARD = `${API_GAME_DATA_BASE}/SendMessageTypeless/HolocacheLeaderboardRequest`
export const API_GAME_CLEAR_USER_HOLOCACHE = `${API_GAME_DATA_BASE}/SendMessageTypeless/HolocacheResetProgressRequest`

/**
 * Node API
 */

export const API_NODES_GET_NODES_OVERVIEW = `${API_NODE_BASE}/v1/public/node_totals`
export const API_NODES_GET_NODES_LIST = `${API_NODE_BASE}/v1/nodes/list`
export const API_NODES_REGISTER_NODE = (id: string) => `${API_NODE_BASE}/v1/nodes/${id}/register`
export const API_NODES_DEREGISTER_NODE = (id: string) =>
    `${API_NODE_BASE}/v1/nodes/${id}/deregister`

// Internal to this project
export const API_SIGNIN_VALIDATE = `/api/auth/validate`
export const API_GET_USER_ASSETS = `/api/user/assets`
export const API_GET_USER_NODES = `/api/user/nodes`
export const API_GET_USER_ADDRESSES_TO_SYNC = `/api/user/addresses-to-sync`
export const API_GET_USER_WALLET_ASSETS = `/api/user/wallet-assets`

export const API_CLAIM_LAND_PLOT = (id: number) => `/api/map/claim-land-plot/${id}`
export const API_GET_LAND_PLOTS = '/api/map/get-land-plots'
export const API_GET_POIS = '/api/map/get-pois'
export const API_GET_TOKEN_DISTRIBUTION = `/api/token/distribution`
export const API_GET_TOKEN_UNLOCK_SCHEDULE = `/api/token/unlock-schedule`
