import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import queryString from 'query-string'

import { ErrorListener, HttpClientRequest, HttpClientResponseType, ResponseError } from './types'
import { HttpClientResponseError } from './Errors'

abstract class HttpClientBase {
    private static accessToken: string | undefined = undefined

    private static errorListeners: ErrorListener[] = []

    private static requireAuthentication = false

    static requireAuth() {
        this.requireAuthentication = true

        if (!this.accessToken) throw new Error('Request requires user to be authenticated')

        return this
    }

    static setAccessToken(accessToken?: string) {
        this.accessToken = accessToken

        return this
    }

    static showErrorNotification(error: AxiosError) {
        const { error: internalServerError } = error.response?.data as ResponseError

        if (internalServerError) {
            toast.error(internalServerError.message)
        }
    }

    static getValidationErrors(error: AxiosError) {
        const { errors: validationServerErrors } = error.response?.data as ResponseError

        return validationServerErrors
    }

    static addErrorListener(errorListenerToAdd: ErrorListener) {
        this.errorListeners.push(errorListenerToAdd)
    }

    static removeErrorListener(listenerToDelete: ErrorListener) {
        this.errorListeners = this.errorListeners.filter(
            (listener) => listener !== listenerToDelete,
        )
    }

    static createParamUrl(url: string, params: Record<string, any>) {
        const qs = queryString.stringify(params)
        return qs ? `${url}?${qs}` : url
    }

    static async request<Res>(
        requestConfig: HttpClientRequest,
    ): Promise<HttpClientResponseType<Res>> {
        const { url, data: payload, ...config } = requestConfig
        let data
        let error

        const options = {
            // credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            ...config,
        } as RequestInit

        if (this.accessToken) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${this.accessToken}`,
            }
        }
        const response = await fetch(url, options)

        if (response.ok) {
            try {
                data = (await response.json()) as Res
            } catch (parseError) {
                error = [
                    {
                        field: 'root',
                        code: response.status,
                        message: 'Failed to parse response as JSON',
                    },
                ]
            }
        } else if (typeof response.json === 'function') {
            try {
                const httpErrors = await response.json()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((httpErrors as any).Message) {
                    error = [
                        {
                            field: 'root',
                            message: httpErrors.Message,
                        },
                    ]
                    // Validation errors
                } else if ((httpErrors as ResponseError).error) {
                    error = [
                        {
                            field: 'root',
                            ...httpErrors.error,
                        },
                    ]
                    // Validation errors
                } else if ((httpErrors as ResponseError).errors) {
                    error = httpErrors.errors
                }
                // Game API error handler
                else if (
                    response.status === 400 &&
                    Array.isArray(httpErrors) &&
                    httpErrors[0]?.type === 'Error'
                ) {
                    error = httpErrors
                }
            } catch (parseError) {
                error = [
                    {
                        field: 'root',
                        code: response.status,
                        message: response.statusText,
                    },
                ]
            }
        } else {
            error = [
                {
                    field: 'root',
                    code: response.status,
                    message: response.statusText,
                },
            ]
        }

        const result: HttpClientResponseType<Res> = {
            // TODO:: Phase out response object
            response: {
                ok: response.ok,
                status: response.status,
                statusText: response.statusText,
            },
            error: error ?? [],
        }

        if (data) result.data = data

        return result
    }

    static async request2<Res>(requestConfig: HttpClientRequest): Promise<Res> {
        const { url, data: payload, ...config } = requestConfig

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            ...config,
        } as RequestInit

        if (this.accessToken) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${this.accessToken}`,
            }
        }

        let responseStatus
        let responseText

        try {
            const response = await fetch(url, options)

            responseStatus = response.status
            responseText = responseStatus === 401 ? 'Unauthorized' : response.statusText
            const contentType = response.headers.get('content-type')

            if (response.ok) {
                let data
                if (contentType && contentType.includes('application/json')) {
                    data = (await response.json()) as Res
                } else {
                    data = (await response.text()) as Res
                }

                return data
            }

            const httpErrors = await response.text().then((data) => {
                try {
                    return data ? JSON.parse(data) : undefined
                } catch (ex) {
                    return {
                        message: data,
                    }
                }
            })

            // Game API returns list of errors
            const error = Array.isArray(httpErrors) ? httpErrors[0] : httpErrors

            throw new HttpClientResponseError({
                code: responseStatus,
                message:
                    //  File Node API
                    error.Message ??
                    //  Do any API's do this?
                    error.message ??
                    //  Everything else
                    error.error?.message ??
                    responseText ??
                    'An error has occurred',
                // Errors - Validation errors
                errors: error.error ? [error.error] : error.errors,
            })
        } catch (exception) {
            // Throw error if it's already of the correct type
            if (exception instanceof HttpClientResponseError) throw exception

            const exceptionMsg =
                responseStatus === 401
                    ? 'Unauthorized'
                    : (exception as Error).message ?? responseText

            throw new HttpClientResponseError({
                message: exceptionMsg,
                code: responseStatus,
            })
        }
    }
}

export default HttpClientBase
